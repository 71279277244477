.rmc-picker,
.rmc-multi-picker {
  height: 238px;
  /*34*7*/
}
.rmc-multi-picker {
  display: -webkit-box;
  display: flex;
  -webkit-box-align: center;
          align-items: center;
}
.rmc-picker-item {
  font-size: 16px;
  height: 40px;
  line-height: 40px;
  padding: 0 30px;
  white-space: nowrap;
  position: relative;
  overflow: hidden;
  text-overflow: ellipsis;
  color: #999;
  width: 100%;
  box-sizing: border-box;
}
.rmc-picker-item-selected{
  color: #484848;
}
.rmc-picker {
  display: block;
  position: relative;
  overflow: hidden;
  width: 100%;
  -webkit-box-flex: 1;
          flex: 1;
  text-align: center;
}
.rmc-picker-mask {
  position: absolute;
  left: 0;
  top: 0;
  height: 100%;
  margin: 0 auto;
  width: 100%;
  z-index: 3;
  background-image: -webkit-gradient(linear, left top, left bottom, from(rgba(255, 255, 255, 0.95)), to(rgba(255, 255, 255, 0.6))), -webkit-gradient(linear, left bottom, left top, from(rgba(255, 255, 255, 0.95)), to(rgba(255, 255, 255, 0.6)));
  background-image: linear-gradient(to bottom, rgba(255, 255, 255, 0.95), rgba(255, 255, 255, 0.6)), linear-gradient(to top, rgba(255, 255, 255, 0.95), rgba(255, 255, 255, 0.6));
  background-position: top, bottom;
  background-size: 100% 204px;
  background-repeat: no-repeat;
}
.rmc-picker-content {
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  z-index: 1;
  font-weight: normal;
}
.rmc-picker-indicator {
  box-sizing: border-box;
  width: 100%;
  height: 40px;
  position: absolute;
  left: 0;
  top: 102px;
  z-index: 3;
  border-top: 1PX solid #e6e6e6;
  border-bottom: 1PX solid #e6e6e6;
}
