/*.r-overflowX-11yh6sk{ touch-action: pan-y; }
.css-1dbjc4n{ touch-action: pan-y; }*/

.r-overflowX-11yh6sk{ touch-action: pan-y !important; }
.r-11yh6sk{ touch-action: pan-y !important;}
/*.css-1dbjc4n{ touch-action: inherit !important; }*/

html,body {
  overflow: hidden;
  position: fixed;
  width: 100%;
  /*max-width: 100%;*/
  height: 100%;
  /*max-height: 100%;*/
  -webkit-overflow-scrolling: touch;
  overscroll-behavior: contain;
  touch-action: none;
}

.r-pointerEvents-12vffkv > *{ overflow-y: auto; overflow-x: hidden;}
.r-12vffkv > *{ overflow-y: auto; overflow-x: hidden; }

*:focus {
  outline: none;
}